import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getGenericTrackingData } from '@finanzcheck/one-segment';
import Image from 'next/image';

import Header from 'components/Header';
import PhoneBox from 'components/Header/PhoneBox';
import { NavigationMobile } from 'components/HeaderNavigation/NavigationMobile';
import type { NavigationItemMobile } from 'components/HeaderNavigation/NavigationMobile';
import NavigationDesktop, {
  NavigationItemDesktop,
} from 'components/HeaderNavigation/NavigationDesktop';
import { MobileMenuLink } from 'components/HeaderNavigation/MobileMenuLink';
import { trackButtonClick } from 'lib/tracking/track';
import { TrackingEvent } from 'lib/tracking/events';

const StyledSeparator = styled('div')`
  background: rgb(211, 211, 211);
  height: 42px;
  width: 1px;
  margin: 0px 20px;
`;

const StyledLoginButton = styled(Link)`
  font-weight: 500;
  display: flex;
  align-items: center;
  & > svg {
    font-size: 12px;
    margin-left: 4px;
  }
`;

// @TODO: Check if this can replace Header and HeaderWithNavigation

export const HeaderWithOptions = ({
  hasNavigationMenu = true,
  hasPhoneBox = true,
  hasLoginLink = true,
  hasTvLogos = false,
}) => {
  const [hasVisibleNavigation, setHasVisibleNavigation] = useState(false);

  const trackLogin = () => {
    trackButtonClick(TrackingEvent.LOGIN_LINK_ONINTERACT, {
      category: 'homepage',
      label: 'Jetzt anmelden',
      ...getGenericTrackingData(),
    });
  };
  return (
    <Header
      contentRight={
        <>
          {hasTvLogos && (
            <Box
              display={{ xs: 'none', md: 'flex' }}
              alignItems={{ md: 'center' }}
            >
              <Image
                src="/assets/images/tv-logos.webp"
                width={440}
                height={24}
                quality={100}
                alt="Bekannt aus dem Fernsehen"
              />
            </Box>
          )}
          {hasPhoneBox && <PhoneBox />}
          {hasLoginLink && (
            <Box
              display={{ xs: 'none', sm: 'none', md: 'flex' }}
              alignItems={{ md: 'center' }}
            >
              <StyledSeparator />
              <StyledLoginButton
                underline="none"
                href="https://home.smava.de/login?"
                role="button"
                onClick={trackLogin}
              >
                Jetzt anmelden
                <ArrowForwardIosIcon color="primary" />
              </StyledLoginButton>
            </Box>
          )}
          {hasNavigationMenu && (
            <MobileMenuLink
              trackingEvent={TrackingEvent.MOBILE_HEADER_MENULINK_ONINTERACT}
              onClick={() => setHasVisibleNavigation(!hasVisibleNavigation)}
            >
              Menü
            </MobileMenuLink>
          )}
        </>
      }
      contentBottom={
        <>
          {hasNavigationMenu && (
            <>
              <NavigationMobile
                visible={hasVisibleNavigation}
                onClose={() => setHasVisibleNavigation(false)}
                hasLoginLink={hasLoginLink}
              >
                {renderNavigationItems}
              </NavigationMobile>
              <NavigationDesktop>{renderNavigationItems}</NavigationDesktop>
            </>
          )}
        </>
      }
    />
  );
};

function renderNavigationItems(
  Item: NavigationItemMobile | NavigationItemDesktop,
  hasLoginLink?: boolean,
) {
  return (
    <>
      {hasLoginLink && (
        <Item
          title="Jetzt anmelden"
          href="https://www.smava.de/sicheres-einloggen-mit-zugangsdaten?"
        />
      )}
      <Item
        title="Kreditvergleich"
        href="https://www.smava.de/kreditvergleich"
      />
      <Item title="Kredit" href="https://www.smava.de/kredit">
        <Item
          title="Kreditrechner"
          href="https://www.smava.de/kredit/rechner/"
        />
        <Item
          title="Kredite ohne Schufa"
          href="https://www.smava.de/kredit/kredit-ohne-schufa/"
        />
        <Item
          title="Sofortkredit"
          href="https://www.smava.de/kredit/sofortkredit/"
        />
        <Item
          title="Kleinkredit"
          href="https://www.smava.de/kredit/kleinkredit/"
        />
        <Item title="Eilkredit" href="https://www.smava.de/kredit/eilkredit/" />
        <Item
          title="Kredit Ratgeber"
          href="https://www.smava.de/kredit/ratgeber/"
        />
      </Item>
      <Item title="Privatkredit" href="https://www.smava.de/privatkredit">
        <Item
          title="Geld leihen"
          href="https://www.smava.de/privatkredit/geld-leihen/"
        />
        <Item
          title="Kredit von Privat"
          href="https://www.smava.de/privatkredit/kredit-von-privat/"
        />
        <Item
          title="Privatkredit Ratgeber"
          href="https://www.smava.de/privatkredit/ratgeber/"
        />
      </Item>
      <Item title="Autokredit" href="https://www.smava.de/autokredit">
        <Item
          title="Autofinanzierung"
          href="https://www.smava.de/autokredit/autofinanzierung/"
        />
        <Item
          title="Schnellkredit"
          href="https://www.smava.de/autokredit/schnellkredit/"
        />
        <Item
          title="Autofinanzierungsrechner"
          href="https://www.smava.de/autokredit/autofinanzierungsrechner/"
        />
        <Item
          title="Autokredit Ratgeber"
          href="https://www.smava.de/autokredit/ratgeber/"
        />
      </Item>
      <Item title="Umschuldung" href="https://www.smava.de/umschuldung">
        <Item
          title="Annuitätendarlehen"
          href="https://www.smava.de/umschuldung/annuitaetendarlehen/"
        />
        <Item
          title="Darlehen"
          href="https://www.smava.de/umschuldung/darlehen/"
        />
        <Item
          title="Dispokredit"
          href="https://www.smava.de/umschuldung/dispokredit/"
        />
        <Item
          title="Umschuldung Ratgeber"
          href="https://www.smava.de/umschuldung/ratgeber/"
        />
      </Item>
      <Item
        title="Baufinanzierung"
        href="https://www.smava.de/baufinanzierung/"
      >
        <Item
          title="Immobilienkredit"
          href="https://www.smava.de/baufinanzierung/immobilienkredit/"
        />
        <Item
          title="Hauskredit"
          href="https://www.smava.de/baufinanzierung/hauskredit/"
        />
        <Item
          title="Wohnungskredit"
          href="https://www.smava.de/baufinanzierung/wohnungskredit/"
        />
        <Item
          title="Baudarlehen"
          href="https://www.smava.de/baufinanzierung/baudarlehen/"
        />
        <Item
          title="Haus finanzieren"
          href="https://www.smava.de/baufinanzierung/haus-finanzieren/"
        />
        <Item
          title="Ratgeber"
          href="https://www.smava.de/baufinanzierung/ratgeber/"
        />
      </Item>
    </>
  );
}