import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { Button, Link } from '@mui/material';
import { trackButtonClick } from 'lib/tracking/track';
import { getGenericTrackingData } from '@finanzcheck/one-segment';
import { TrackingEvent } from 'lib/tracking/events';

const StyledDrawer = styled(Drawer)`
  ${(props) => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

const StyledLoginButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(1, 1.5),
  a: {
    padding: 0,
    textAlign: 'center',
    fontWeight: 500,
    color: 'white',
  },
}));

interface NavigationMobileProps {
  children: (
    item: typeof NavigationItem,
    hasLoginLink?: boolean,
  ) => React.ReactElement;
  visible: boolean;
  onClose: () => void;
  hasLoginLink?: boolean;
}

export const NavigationMobile: React.FC<NavigationMobileProps> = ({
  children,
  visible,
  onClose,
  hasLoginLink,
}) => {
  const trackLogin = () => {
    trackButtonClick(TrackingEvent.LOGIN_LINK_ONINTERACT_MOBILE, {
      category: 'homepage',
      label: 'Jetzt anmelden',
      ...getGenericTrackingData(),
    });
  };
  return (
    <StyledDrawer
      anchor="right"
      open={visible}
      onClose={onClose}
      sx={{ '& .MuiDrawer-paper': { width: 240 } }}
    >
      <List>{children(NavigationItem, hasLoginLink)}</List>
      <StyledLoginButton
        variant="contained"
        color="primary"
        size="medium"
        onClick={trackLogin}
      >
        <Link
          href={`https://${process.env.DOMAIN_CUSTOMER_AREA}` || ''}
          style={{ textDecoration: 'none' }}
        >
          Jetzt anmelden
        </Link>{' '}
      </StyledLoginButton>
    </StyledDrawer>
  );
};

interface NavigationItemMobileProps {
  title: string;
  href: string;
  hasLoginLink?: boolean;
  children?: React.ReactNode;
}

const NavigationItem: React.FC<NavigationItemMobileProps> = ({
  title,
  href,
  hasLoginLink,
  children,
}) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const listItemProps = children
    ? { onClick: () => setSubMenuOpen(!subMenuOpen) }
    : { component: 'a', href };
  return hasLoginLink || title !== 'Jetzt anmelden' ? (
    <>
      <ListItem button {...listItemProps}>
        <ListItemText primary={title} />
        {children && (subMenuOpen ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      {children && (
        <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
          <List>
            <NavigationItem href={href} title={title} />
            {children}
          </List>
        </Collapse>
      )}
    </>
  ) : (
    <></>
  );
};

export type NavigationItemMobile = typeof NavigationItem;