import { styled } from '@mui/material/styles';
import { TrackButton } from 'components/TrackButton';

export const MobileMenuLink = styled(TrackButton)(({ theme }) => ({
  textTransform: 'inherit',
  marginTop: 0,
  marginRight: theme.spacing(-1),
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));
