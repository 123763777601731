import React, { useRef, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styles from './Overrides.module.css';

const Background = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export default function NavigationDesktop({
  children,
}: {
  children: (item: typeof NavigationItem) => React.ReactElement;
}) {
  return (
    <Background>
      <Container component="nav" role="navigation">
        <Box component="ul" display="flex" flexWrap="wrap" p={0} m={0} ml={-2}>
          {children(NavigationItem)}
        </Box>
      </Container>
    </Background>
  );
}

const StyledLink = styled('a')`
  font-size: small;
  text-transform: uppercase;
  color: #000;
  line-height: 24px;
`;

export type NavigationItemDesktop = typeof NavigationItem;
export function NavigationItem({
  title,
  href,
  children,
}: {
  title: string;
  href: string;
  children?: React.ReactElement<typeof NavigationItem>[];
}) {
  const ref = useRef<HTMLLIElement>(null);
  const [isFocusingAnchor, setIsFocusingAnchor] = useState(false);
  const [isFocusingMenu, setIsFocusingMenu] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  useEffect(() => {
    if (!children) return;

    if (isFocusingAnchor || isFocusingMenu) {
      setSubMenuOpen(true);
    } else {
      setSubMenuOpen(false);
      ref?.current?.blur();
    }
  }, [children, isFocusingAnchor, isFocusingMenu]);

  return (
    <>
      {title !== 'Jetzt anmelden' && (
        <MenuItem
          role="listitem"
          ref={ref}
          onFocus={() => setIsFocusingAnchor(true)}
          onMouseOver={() => setIsFocusingAnchor(true)}
          onMouseLeave={() => setIsFocusingAnchor(false)}
        >
          <StyledLink href={href}>{title}</StyledLink>
        </MenuItem>
      )}
      {children && (
        <Menu
          open={subMenuOpen}
          autoFocus={false}
          disableAutoFocus={true}
          disableAutoFocusItem={true}
          anchorEl={ref.current}
          hideBackdrop={true}
          classes={{ root: styles.popoverRoot }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          MenuListProps={{
            onMouseOver: () => setIsFocusingMenu(true),
            onFocus: () => setIsFocusingMenu(true),
            onMouseLeave: () => setIsFocusingMenu(false),
          }}
        >
          {children}
        </Menu>
      )}
    </>
  );
}
