import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiIconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import PhoneIcon from './PhoneIcon';
import { trackButtonClick } from 'lib/tracking/track';
import { TrackingEvent } from 'lib/tracking/events';

const StyledPhoneIcon = styled(PhoneIcon)`
  fill: #848484;
  height: 24px;
  width: 24px;
`;

const PhoneNumber = styled('a')`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
  transition: color 0.25s;
  :hover {
    color: rgb(116, 196, 127);
  }
`;

const IconButton = styled(MuiIconButton)`
  border-radius: 50% !important;
`;

const trackPhoneClick = () => {
  trackButtonClick(TrackingEvent.PHONEBOX_BUTTON_ONINTERACT)
}

function PhoneBox() {
  return (
    <Box display="flex" alignItems="center">
      <PhoneNumber href="tel:0800 000 98 00" onClick={trackPhoneClick}>
        <IconButton aria-label="Anrufen">
          <StyledPhoneIcon />
        </IconButton>
      </PhoneNumber>
      <Box
        display={{ xs: 'none', sm: 'none', md: 'flex' }}
        flexDirection="column"
        ml={1.5}
      >
        <Typography variant="body1" fontSize="14px" margin="0">
          <PhoneNumber href="tel:0800 000 98 00" onClick={trackPhoneClick}>0800 000 98 00</PhoneNumber>
        </Typography>
        <Typography
          fontSize="14px"
          color="textSecondary"
          variant="body2"
          margin="0"
        >
          Kostenlose Beratung
        </Typography>
      </Box>
    </Box>
  );
}

export default PhoneBox;
